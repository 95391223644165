import { useMemo, useState } from "react";
import useSWR from "swr";
import { get, groupBy, isFunction } from "lodash";
import useCart from "grandus-lib/hooks/useCart";

export const CUSTOM_MEMBER_CONSTANT = "_grandus_member";

const useMember = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { cartUpdate } = useCart();
  const { data: member, mutate, isValidating } = useSWR(
    `/api/pages/member`,
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const { data: operationUnitsResponse } = useSWR(
    "/api/lib/v1/operation-units?perPage=999", 
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  const operationUnits = get(operationUnitsResponse, "operationUnits", []);
  const groupedOperationUnits = useMemo(() => groupBy(operationUnits, "town.district.name"), [operationUnits]);

  const isActive = () => {
    return get(member, "active", false);
  };

  const setDistrict = async (district, callback) => {
    setIsLoading(true);
    try {
      await mutate(
        await fetch(
          `/api/pages/member?district=${encodeURIComponent(district)}`,
          {
            method: "POST",
          }
        )
          .then((result) => result.json())
          .then((result) => {
            if (isFunction(callback)) {
              callback(result);
            }
            return result;
          }),
        false
      );
    } catch (error) {
      console.error("An unexpected error happened:", error);
    }
    setIsLoading(false);
  };

  const setOperationUnit = async (operationUnit, callback) => {
    setIsLoading(true);
    try {
      await mutate(
        await fetch(`/api/pages/member`, {
          method: "PUT",
          body: JSON.stringify({ operationUnit: operationUnit }),
        })
          .then((result) => result.json())
          .then((result) => {
            if (isFunction(callback)) {
              callback(result);
            }
            cartUpdate({
              deliveryType: null,
              paymentType: null,
              operationUnitId: null,
            });
            return result;
          }),
        false
      );
    } catch (error) {
      console.error("An unexpected error happened:", error);
    }
    setIsLoading(false);
  };

  const unsetDistrict = async (callback) => {
    setIsLoading(true);
    await mutate(
      await fetch(`/api/pages/member?district=true`, {
        method: "DELETE",
      })
        .then((result) => result.json())
        .then((result) => {
          if (isFunction(callback)) {
            callback(result);
          }
          return result;
        }),
      false
    );
    setIsLoading(false);
  };

  const unsetOperationUnit = async (callback) => {
    setIsLoading(true);
    await mutate(
      await fetch(`/api/pages/member`, {
        method: "DELETE",
      })
        .then((result) => result.json())
        .then((result) => {
          if (isFunction(callback)) {
            callback(result);
            cartUpdate({
              deliveryType: null,
              paymentType: null,
              operationUnitId: null,
            });
          }
          return result;
        }),
      false
    );
    setIsLoading(false);
  };

  return {
    operationUnits: groupedOperationUnits,
    member,
    mutateMember: mutate,
    isLoading: isValidating || isLoading,
    isActive,
    setDistrict,
    setOperationUnit,
    unsetOperationUnit,
    unsetDistrict
  };
};

export default useMember;
