import Head from "next/head";

// import 'scripts/wdyr';

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "../styles/custom-antd.css";
import "../styles/custom-grid.scss";
import "../style/css/style.scss";
import "react-image-lightbox/style.css";

import { useMemo, useEffect } from "react";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import Router from "next/router";
import TagManager from "grandus-lib/utils/gtag";

import InfoBox from "components/InfoBox";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

import NProgress from "nprogress";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// export function reportWebVitals(metric) {
//   if (metric.label === "custom") {
//     console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
//   }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const CookieConsent = ({}) => {
  return (
    <>
      <script src="https://cookiehub.net/c2/9da5f104.js" />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            document.addEventListener("DOMContentLoaded", function(event) {
              var cpm = {};
              window.cookiehub.load(cpm);
            });
          `,
        }}
      />
    </>
  );
};

const GrandusApp = ({ Component, pageProps }) => {
  useEffect(() => {
    TagManager.registerPageViewTracking(Router);
  }, []);

  return (
    <>
      <Head>
        <CookieConsent />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css"
        />
      </Head>
      {useMemo(
        () => (
          <InfoBox />
        ),
        []
      )}
      <Header />
      <Component {...pageProps} />
      {useMemo(
        () => (
          <Footer />
        ),
        []
      )}
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default GrandusApp;
