import { LoadingOutlined } from "@ant-design/icons";
import Step1 from "components/memberSelection/Step1";
import Step2 from "components/memberSelection/Step2";
import isEmpty from "lodash/isEmpty";
import useMember from "utils/useMember";

const Steps = ({ setModalVisible }) => {
  const { operationUnits, member, isLoading } = useMember();
  
  if (!isEmpty(operationUnits) && !isLoading) {
    if (!isEmpty(member?.district)) {
      return <Step2 setModalVisible={setModalVisible} />;
    }

    return <Step1 setModalVisible={setModalVisible} />;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

export default Steps;
