import useUser from "grandus-lib/hooks/useUser";
import Link from "next/link";

import styles from "./AuthButton.module.scss";

const AuthButton = () => {
    const {user} = useUser();

    if (user) {
        return (
            <Link href="/ucet/profil" as={`/ucet/profil`}>
                <a>
                    <img src="/img/user.svg" alt="user" className={"svg " + styles.userImg}/>
                </a>
            </Link>
        );
    }

    return (
        <Link href="/prihlasenie" as={`/prihlasenie`}>
            <a>
                <img src="/img/login.svg" alt="user" className={"svg " + styles.loginImg}/>
            </a>
        </Link>
    );
};

export default AuthButton;
