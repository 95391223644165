import useMember from "utils/useMember";
import get from "lodash/get";

import Districts from "components/memberSelection/Districts";
import Shops from "components/memberSelection/Shops";

import styles from "components/memberSelection/MemberSelection.module.scss";
import { Button } from "antd";

import { LoadingOutlined } from "@ant-design/icons";


const Step2 = ({ setModalVisible, ...rest }) => {
  
  const {
      member,
      isLoading,
      operationUnits,
      setOperationUnit,
      unsetOperationUnit
  } = useMember();

  return (
    <section className={styles.step2}>
      <div className={styles.header}>
        <h2>
        Vyberte si svoju predajňu<br/> alebo pokračujte na e-shop predajne
        </h2>
        <Button
          type="primary"
          onClick={() => {
            setModalVisible(false);
            // window.location.reload();
          }}
        >
          {member?.operationUnit?.id ? "Zavrieť" : "Pokračovať bez výberu"}
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <Districts setModalVisible={setModalVisible}/>
        </div>
        <div className={styles.right}>
          <Shops 
            setModalVisible={setModalVisible} 
            shops={get(operationUnits, member?.district, [])} 
            member={member}
            setOperationUnit={setOperationUnit}
            unsetOperationUnit={unsetOperationUnit}
          />
          {isLoading ? (
            <div className={styles?.loading}>
              <LoadingOutlined />
              <span>Čakajte prosím...</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.footer}>
      </div>
    </section>
  );
};

export default Step2;
