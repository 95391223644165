import Districts from "components/memberSelection/Districts";
import useMember from "utils/useMember";

import { Button } from "antd";

import styles from "components/memberSelection/MemberSelection.module.scss";

const Step1 = ({ setModalVisible, ...rest }) => {

  const {
    unsetDistrict
  } = useMember();

  return (
    <section className={styles.step1}>
      <div className={styles.header}>
        <h2>Vyberte si svoju predajňu</h2>
        <Button
          type="primary"
          onClick={() => {
            unsetDistrict();
            setModalVisible(false);
          }}
        >
          Pokračovať bez výberu
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <Districts setModalVisible={setModalVisible} />
        </div>
        <div className={styles.right}>
          <p>
            {/* <img src="/img/arrow-left.svg" alt="arrow left" /> */}
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <title />
              <g data-name="Layer 2" id="Layer_2">
                <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
              </g>
            </svg>
            Vyberte <br /> okres
          </p>
        </div>
      </div>
      <div className={styles.footer}></div>
    </section>
  );
};

export default Step1;
