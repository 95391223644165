import useCart from "grandus-lib/hooks/useCart";
import { get } from "lodash";

import Link from "next/link";

import styles from "./CartButton.module.scss";

const CartButton = () => {
  const { cart, isLoading } = useCart();
  return (
    <Link href="/kosik" as={`/kosik`}>
      <a className="cart">
        <span className="price">
          {get(cart, "sumData.priceFormatted", "košík")}
        </span>
        <div className="icon">
          {get(cart, "pieceCount", 0) ? (
            <span className="badge">{get(cart, "pieceCount", 0)}</span>
          ) : (
            ""
          )}
          <img src="/img/cart.svg" alt="compare" className={"svg " + styles.cartButtonImg} />
        </div>
      </a>
    </Link>
  );
};

export default CartButton;
