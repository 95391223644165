import Link from "next/link";
import styles from "components/header/WishlistButton.module.scss";

const WishlistButton = () => {
    return (
        <Link href="/wishlist" as={`/wishlist`}>
            <a>
                <img src="/img/favourite.svg" alt="wishlist" className={"svg " + styles['wishlistImg']}/>
            </a>
        </Link>
    );
};

export default WishlistButton;
