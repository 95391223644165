import styles from "./SideMenu.module.scss"
import useSWR from "swr";
import {get, map, isEmpty} from "lodash";
import Link from "next/link";
import {getCategoryLinkAttributes} from "../../grandus-lib/hooks/useFilter";

const SideMenu = (props) => {

    const {data} = useSWR("/api/lib/v1/categories", (url) =>
        fetch(url).then((r) => r.json())
    );
    const {data: statics} = useSWR("/api/lib/v1/statics?location=1", (url) =>
        fetch(url).then((r) => r.json())
    );

    const {sideMenuOpen, setSideMenuOpen} = props;
    const sideBarClasses = styles.sideMenuContainer + (sideMenuOpen ? (' ' + styles.sideMenuContainerVisible) : '');

    return (
        <>
            <div className={sideBarClasses}>
                <div className={styles.sideBarContent}>
                    <div className={styles.menuCloseWrapper}>
                        <a href="#" className={styles.menuClose} onClick={() => {
                            setSideMenuOpen(false)
                        }}>
                            <i className="fas fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                    {!isEmpty(data) ? (
                        <ul
                            className={styles.sideMenuList}
                        >
                            {map(data, (item, index) => {
                                return (
                                    <li key={`menu-item-${get(item, "id")}-${index}`}
                                        className={`${styles.sideMenuListItem} ${item?.hash == "HIGHLIGHT" ? styles.highlight : ""}`}>
                                        {item?.externalUrl ? (
                                            <a href={item?.externalUrl}>{item?.name}</a>
                                        ) : (
                                            <Link
                                                {...getCategoryLinkAttributes(get(item, "urlName"))}
                                                scroll={true}
                                            >
                                                <a>{get(item, "name")}</a>
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : ""}

                    <div className={styles.sideMenuSpacer}></div>

                    <h3 className={styles.sideMenuTitle}>Užitočné informácie</h3>

                    {!isEmpty(statics) ? (
                        <ul className={styles.sideMenuList}>
                            {map(statics, (item, index) => {
                                    return (
                                        <li key={`menu-static-${item?.id}-${index}`} className={styles.sideMenuListItem}>
                                            <Link
                                                href="/stranka/[id]"
                                                as={`/stranka/${get(item, "urlTitle")}`}
                                                scroll={true}
                                            >
                                                <a>{get(item, "title")}</a>
                                            </Link>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    )
}

export default SideMenu;