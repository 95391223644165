import useSWR from "swr";
import { get, map, slice, round } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

import styles from "./Footer.module.scss";

import dynamic from "next/dynamic";
import NewsletterForm from "components/forms/Newsletter";
import {handleSocialClick} from "utils/customHelpers";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const Footer = () => {
  const { data } = useSWR(
    "/api/pages/footer",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const statics = get(data, "statics", []);
  const categories = get(data, "categories", []);

  return (
    <footer>
      {/* <div className="icons">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="item">
                <h3>Platba vopred</h3>
                <img src="/img/payment-icons.png" alt="payment" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <h3>Platba vopred</h3>
                <img src="/img/payment-icons2.png" alt="payment" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <h3>Platba vopred</h3>
                <img src="/img/payment-icons3.png" alt="payment" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <a href="#" className="logo">
                <img src="/img/logo-full-color.svg" alt="logo" />
              </a>
              <div className="contact-info">
                {/* <a href={`tel:${get(webinstance, "globalSettings.phone")}`}>
                  <span className="icon">
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  {get(webinstance, "globalSettings.phone")}
                </a> */}
                <a href={`mailto:${webinstance?.adminEmail}`}>
                  <span className="icon">
                    <i className="fas fa-envelope-open-text"></i>
                  </span>
                  {webinstance?.adminEmail}
                </a>
                <Link href="/predajne">
                  <a className={styles["map-anchor"]}>
                    <span className="icon">
                      <i className="fas fa-map-marker"></i>
                    </span>
                    zobraziť na mape
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <h3>Kategórie</h3>
              {categories ? (
                <ul>
                  {map(categories, (category, index) => {
                    return (
                      <li key={`footer-category-${category?.id}-${index}`}>
                        {category?.externalUrl ? (
                          <a href={category?.externalUrl}>{category?.name}</a>
                        ) : (
                          <Link
                            {...getCategoryLinkAttributes(
                              get(category, "urlName")
                            )}
                            scroll={true}
                          >
                            <a>{get(category, "name")}</a>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-3">
              <h3>Užitočné informácie</h3>
              {statics ? (
                <ul>
                  {map(
                    slice(statics, 0, round(statics.length / 2)),
                    (item, index) => {
                      return (
                        <li key={`footer-static-${item?.id}-${index}`}>
                          <Link
                            href="/stranka/[id]"
                            as={`/stranka/${get(item, "urlTitle")}`}
                            scroll={true}
                          >
                            <a>{get(item, "title")}</a>
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-3">
              <h3>&nbsp;</h3>
              {statics ? (
                <ul>
                  {map(
                    slice(statics, round(statics.length / 2)),
                    (item, index) => {
                      return (
                        <li key={`footer-static-${item?.id}-${index}`}>
                          <Link
                            href="/stranka/[id]"
                            as={`/stranka/${get(item, "urlTitle")}`}
                            scroll={true}
                          >
                            <a>{get(item, "title")}</a>
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="newsletter">
        <div className="container">
          <div className="newsletter-wrap">
            <div className="social">
              <a
                target="_blank"
                href="https://www.facebook.com/EuronicsSlovensko/"
                onClick={() => handleSocialClick("facebook")}
              >
                <i className="fab fa-facebook" aria-hidden="true"></i>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/EuronicsSlovakia"
                onClick={() => handleSocialClick("youtube")}
              >
                <i className="fab fa-youtube" aria-hidden="true"></i>
              </a>
            </div>
            <div className="form-wrapper">
              <p>Nezmeškajte žiadne akcie a zľavy:</p>
              <form id="search" action="#" method="get">
                <input
                  type="email"
                  name="newsletter"
                  id="newsletter"
                  autoComplete="off"
                  placeholder="Vaša e-mailová adresa"
                />
                <button className="button">Odoslať</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          2020 © ${get(webinstance, "globalSettings.name_of_company")} |
          prevádzkované eshop systémom Grandus od spoločnosti For Best Clients,
          s.r.o.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
