import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import useMember from "utils/useMember";
import MemberSelection from "components/memberSelection";

const MemberSelectionButton = (props) => {
  const { member } = useMember();
  const [modalVisible, setModalVisible] = useState(
    !isEmpty(member) && !member?.active
  );
  const handleModalShow = (member) => {
    if (!isEmpty(member) && !member?.active) {
      setModalVisible(true);
    }
  };

  return (
    <>
      <button
        className="stores"
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {member?.operationUnit?.district || member?.district
          ? (
            <>
            {member?.operationUnit?.district ?? member?.district}
              {!isEmpty(member?.operationUnit) ? (
                <>
                  {": "} <span>{member?.operationUnit?.name}</span>
                </>
              ) : (
                ""
              )}
            </>
          )
          : 'Vyberte si predajňu'
        }
        <i className="fas fa-caret-down"></i>
      </button>
      <MemberSelection
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default MemberSelectionButton;
