import TagManager from "grandus-lib/utils/gtag";
import CustomGaEvents from "utils/customGA";

export const handleContactClick = (type, valueType, value) => {
	TagManager.push(CustomGaEvents.contact_click(type,valueType,value));
}

export const handleSocialClick = (type) => {
	TagManager.push(CustomGaEvents.social_media(type));
}
