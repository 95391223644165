import { useEffect, useState, useRef } from "react";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { first, get, isEmpty, map, slice } from "lodash";
import Link from "next/link";

import styles from "./Search.module.scss";
import Form from "antd/lib/form/Form";
import { useRouter } from "next/router";

import TopProduct from "components/autocomplete/TopProduct";
import ProductRow from "components/autocomplete/ProductRow";
import TagManager from "grandus-lib/utils/gtag";
import EnhancedEcommerce from "grandus-lib/utils/ecommerce";

const Results = ({ products, brands, categories, operationUnits, onCloseClick }) => {
  const topProduct = first(products);
  const otherProducts = slice(products, 1, -1);
  return (
    <div className={styles?.results}>
      <div className={styles?.resultsWrapper}>
        <span className={styles?.close} onClick={onCloseClick}>
          <CloseCircleOutlined />
        </span>
        <section className={styles?.resultsLeft}>
          <h2>Značky</h2>
          {!isEmpty(brands) ? (
            <ul className={styles?.inline}>
              {map(brands, (brand) => (
                <li key={`autosuggest-brand-${brand?.urlName}`}>
                  <Link
                    href="/vyhladavanie/[term]"
                    as={`/vyhladavanie/${brand?.urlName}`}
                  >
                    <a className={styles?.tag}>{brand?.name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          ) : ("Nenašli sa žiadne vyhovujúce značky")}

          <h2>Kategórie</h2>
          {!isEmpty(categories) ? (
            <ul>
              {map(categories, (category) => (
                <li key={`autosuggest-category-${category?.urlName}`}>
                  <Link
                    href="/kategoria/[category]/[[...parameters]]"
                    as={`/kategoria/${category?.urlName}`}
                  >
                    <a>{category?.name}</a>
                  </Link>
                </li>
              ))}
            </ul>
          ) : ("Nenašli sa žiadne vyhovujúce kategórie")}

          <h2>Predajne</h2>
          {!isEmpty(operationUnits) ? (
            <ul>
              {map(operationUnits, (operationUnit) => (
                  <li key={`autosuggest-operationUnut-${operationUnit?.urlName}`}>
                    <Link
                        href="/predajna/[operationUnit]/[[...parameters]]"
                        as={`/predajna/${operationUnit?.id}`}
                    >
                      <a>{operationUnit?.name}</a>
                    </Link>
                  </li>
              ))}
            </ul>
          ): ("Nenašli sa žiadne predajne")}
        </section>
        <section className={styles?.topProduct}>
          <h2>Top produkt</h2>
          <TopProduct {...topProduct} />
        </section>
        <section className={styles?.resultsRight}>
          <h2>Produkty</h2>
          {map(otherProducts, (product) => (
            <ProductRow
              key={`autosuggest-product-${product?.id}`}
              {...product}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

const Search = () => {
  const router = useRouter();
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [results, setResults] = useState([]);

  const wrapperRef = useRef(null);

  const fetchResults = async (value) => {
    setLoading(true);
    const data = await fetch(`/api/lib/v1/autosuggest?search=${value}`).then(
      (result) => result.json()
    );
    setResults(data);
    setLoading(false);
  };

  const handleClose = () => {
    setValue("");
    setResults([]);
  };

  const handleChange = (e) => {
    const value = get(e, "target.value", "");
    setIsFocused(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setValue(value);
    setTyping(true);
    setTypingTimeout(
      setTimeout(() => {
        setTyping(false);
        fetchResults(value);
        if(!isEmpty(value)){
          TagManager.push(EnhancedEcommerce.search(value));
        }
      }, 1000)
    );
  };

  const handleSubmit = () => {
    if (value.length) {
      handleClose();
      router.push({
        pathname: "/vyhladavanie/[term]/[[...parameters]]",
        query: { term: value },
      });
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      handleClose(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, isFocused]);

  useEffect(() => {
    router.events.on("routeChangeStart", handleClose);
    return () => {
      router.events.off("routeChangeStart", handleClose);
    };
  }, []);

  return (
    <div className={`search ${styles["search-wrapper"]}`} ref={wrapperRef}>
      <Form onFinish={handleSubmit} className="search">
        <input
          onChange={handleChange}
          onBlur={handleBlur}
          name="term"
          type="text"
          value={value}
          autoComplete="off"
          placeholder={"Zadajte hľadaný výraz"}
        />
        <button className={"button"} type="submit">
          {typing || loading ? (
            <LoadingOutlined spin />
          ) : (
            <i className="fas fa-search"></i>
          )}
        </button>
      </Form>
      {!isEmpty(results) ? (
        <Results {...results} onCloseClick={handleClose} />
      ) : null}
    </div>
  );
};

export default Search;
