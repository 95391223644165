import Link from "next/link";
import styles from "components/header/ShopsButton.module.scss";

const ShopsButton = () => {
    return (
        <Link href="/predajne" as={`/predajne`}>
            <a className={styles.shopsButton}>
                <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
            </a>
        </Link>
    );
};

export default ShopsButton;
