import { useState } from "react";
import useSWR from "swr";
import { get, map, slice, round } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import isEmpty from "lodash/isEmpty";
import MegaMenu from "./MegaMenu";
import styles from "./MegaMenu.module.scss";

const Menu = () => {
  const [isMegamenuOpen, setMegamenuOpen] = useState(false);
  const [isResponsiveMenuOpen, setResponsiveMenuOpen] = useState(false);

  const { data } = useSWR("/api/lib/v1/categories", (url) =>
    fetch(url).then((r) => r.json())
  );
  const { data: statics } = useSWR("/api/lib/v1/statics?location=1", (url) =>
    fetch(url).then((r) => r.json())
  );

  return (
    <nav className="main">
      <button
        className="submemu"
        onClick={(e) => setMegamenuOpen(!isMegamenuOpen)}
      >
        <i className="fas fa-bars"></i>
      </button>
      <button
        className="menu-responsive js-responsive-button"
        onClick={(e) => setResponsiveMenuOpen(!isResponsiveMenuOpen)}
      >
        <span className="text">Kategórie</span>
        <i className="fas fa-bars"></i>
      </button>
      {!data ? (
        <ul className="categories">
          <li>&nbsp;</li>
        </ul>
      ) : (
        <ul
          className="categories"
          style={isResponsiveMenuOpen ? { display: "block" } : {}}
        >
          {data.map((item, index) => {
            return (
              <li key={`menu-item-${get(item, "id")}-${index}`} className={item?.hash == "HIGHLIGHT" ? "highlight" : "" + " " + styles.menuItem}>
                {item?.externalUrl ? (
                  <a href={item?.externalUrl}>{item?.name}</a>
                ) : (
                  <Link
                    {...getCategoryLinkAttributes(get(item, "urlName"))}
                    scroll={true}
                  >
                    <a>{get(item, "name")}</a>
                  </Link>
                )}
                {!isEmpty(item?.children) ? (
                  <MegaMenu items={item?.children} />
                ) : ""}
              </li>
            );
          })}
        </ul>
      )}

      <div
        className="megamenu"
        style={isMegamenuOpen ? { display: "block" } : {}}
      >
        <div className="row">
          <div className="col-xl-4 col-lg-8">
            <div className="box-item">
              <h3>Užitočné informácie</h3>
              <div className="row">
                {statics ? (
                  <div className="col-12 col-sm-6 pl-0">
                    <ul style={{ display: "block" }} className="xs-mb-0">
                      {map(
                        slice(statics, 0, round(statics.length / 2)),
                        (item, index) => {
                          return (
                            <li key={`menu-static-${item?.id}-${index}`}>
                              <Link
                                href="/stranka/[id]"
                                as={`/stranka/${get(item, "urlTitle")}`}
                                scroll={true}
                              >
                                <a>{get(item, "title")}</a>
                              </Link>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}

                {statics ? (
                  <div className="col-12 col-sm-6 pl-0">
                    <ul style={{ display: "block" }} className="not-first">
                      {map(
                        slice(statics, round(statics.length / 2)),
                        (item, index) => {
                          return (
                            <li key={`menu-static-${item?.id}-${index}`}>
                              <Link
                                href="/stranka/[id]"
                                as={`/stranka/${get(item, "urlTitle")}`}
                                scroll={true}
                              >
                                <a>{get(item, "title")}</a>
                              </Link>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4">
            <div className="box-item">
              <h3>Kategórie</h3>
              {data ? (
                <ul style={{ display: "block" }}>
                  {map(data, (category, index) => {
                    return (
                      <li key={`footer-category-${category?.id}-${index}`}>
                        {category?.externalUrl ? (
                          <a href={category?.externalUrl}>{category?.name}</a>
                        ) : (
                          <Link
                            {...getCategoryLinkAttributes(
                              get(category, "urlName")
                            )}
                            scroll={true}
                          >
                            <a>{get(category, "name")}</a>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
