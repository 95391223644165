import {Fragment, useState} from "react";
import UserButton from "components/user/AuthButton";
import useSWR from "swr";

import get from "lodash/get";
import map from "lodash/map";
import CartButton from "components/cart/CartButton";
import CompareButton from "components/header/CompareButton";
import Transition from "pages/_transition";

import Link from "next/link";

import Menu from "components/menu/Menu";
import Search from "components/header/Search";
import MemberSelectionButton from "components/memberSelection/MemberSelectionButton";
import WishlistButton from "./WishlistButton";
import ShopsButton from "./ShopsButton";
import MenuOpener from "./MenuOpener";
import SideMenu from "./SideMenu";
import {handleContactClick} from "utils/customHelpers";

const Header = () => {
  const { data } = useSWR(
    "/api/pages/header",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const webinstance = get(data, "webinstance", {});
  return (
    <header className="page-header">
      <div className="topbar">
        <div className="left">
          <MemberSelectionButton />
          <div className="hidden-xs-down">
            {get(webinstance, "pagesHeader", []).length ? (
              <div className="links">
                {map(get(webinstance, "pagesHeader", []), (page) => {
                  return (
                    <Fragment key={`header-page-${page?.id}`}>
                      {page?.externalUrl ? (
                        <a href={get(page, "externalUrl")}>
                          {get(page, "title")}
                        </a>
                      ) : (
                        <Link
                          href="/stranka/[id]"
                          as={`/stranka/${get(page, "urlTitle")}`}
                          scroll={true}
                        >
                          <a>{get(page, "title")}</a>
                        </Link>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="right">
          <a
            href={`mailto:${get(webinstance, "adminEmail")}`}
            onClick={() => handleContactClick("email","email",get(webinstance, "adminEmail"))}
          >
            {get(webinstance, "adminEmail")}
          </a>
          {/* <a href={`tel:${get(webinstance, "globalSettings.phone")}`}>
            {get(webinstance, "globalSettings.phone")}
          </a> */}
        </div>
      </div>
      <div className="actions">
        <Link href="/" as={`/`} scroll={true}>
          <a className="logo">
            <img src="/img/logo-white.svg" alt="logo" />
          </a>
        </Link>
        <Search />
        <div className="right">
          <ShopsButton />
          <WishlistButton />
          <CompareButton />
          <CartButton />
          <UserButton />
          <MenuOpener setSideMenuOpen={setSideMenuOpen}/>
        </div>
      </div>
      <Transition>
        <Menu />
      </Transition>

        <SideMenu sideMenuOpen={sideMenuOpen} setSideMenuOpen={setSideMenuOpen}/>
    </header>
  );
};

export default Header;
