import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import find from "lodash/find";
import replace from "lodash/replace";

import { useRouter } from "next/router";
import { Button } from "antd";
import Link from "next/link";

import styles from "components/memberSelection/MemberSelection.module.scss";

const ESHOP_CATEGORY_HASH = "eshop_category";
const ESHOP_PPRODUCT_HASH = "eshop_product";
const CATEGORY_ROUTE = "/kategoria/[category]/[[...parameters]]";
const PRODUCT_ROUTE = "/produkt/[id]";

/**
 * 
 * @param String url of operation unit 
 * @returns String Url without protocol and www
 */
 const getDomain = (url) => {

    let result = replace(url, "https://", "");
    result = replace(result, "http://", "");
    result = replace(result, "www.", "");
  
    return result;
  }

const getLink = (operationUnit, data = null) => {
    const router = useRouter();
    let link = get(operationUnit, "web", "");
    switch (router?.route) {
      case CATEGORY_ROUTE:
        const eshopCategoryParam = find(operationUnit?.parameters, [
          "hash",
          ESHOP_CATEGORY_HASH,
        ]);
        const categoryProps = get(router, [
          "components",
          CATEGORY_ROUTE,
          "props",
          "pageProps",
          "category",
        ]);
        if (!isEmpty(eshopCategoryParam)) {
          link = get(eshopCategoryParam, "value") + get(categoryProps, "id");
        }
        break;
      case PRODUCT_ROUTE:
        const eshopProductParam = find(operationUnit?.parameters, [
          "hash",
          ESHOP_PPRODUCT_HASH,
        ]);
        const productProps = get(router, [
          "components",
          PRODUCT_ROUTE,
          "props",
          "pageProps",
          "product",
        ]);
        if (!isEmpty(eshopProductParam)) {
          link = get(eshopProductParam, "value") + get(productProps, "ean");
        }
        break;
    }
    return link;
};

const Shops = ({ member, shops, setModalVisible, setOperationUnit, unsetOperationUnit, ...rest }) => {

  const handleSelectShopClick = (operationUnit) => async (e) => {
      if (!isEmpty(operationUnit)) {
        await setOperationUnit({
          id: operationUnit?.id,
          name: operationUnit?.name,
          district: get(operationUnit, 'town.district.name', null)
        });
        setModalVisible(false);
      }
    };
    const handleRemoveShopClick = (e) => {
      unsetOperationUnit();
    };

  let shopExternalLink = null;

  return (
    <ul className={styles?.stores}>
        {map(shops, (shop, index) => {
            shopExternalLink = getLink(shop);
            return (
              <li key={`shop-${shop?.id}-${index}`}>
                  {get(shop, "name")}
                  <div className={styles?.buttons}>
                  {member?.operationUnit?.id == shop?.id ? (
                      <Button
                      size="small"
                      danger
                      onClick={handleRemoveShopClick}
                      >
                      Zrušiť
                      </Button>
                  ) : (
                      <Button
                      size="small"
                      type="primary"
                      onClick={handleSelectShopClick(shop)}
                      >
                      Zvoliť ako moju predajňu
                      </Button>
                  )}
                  {!isEmpty(shopExternalLink) ? (
                      <Link href={shopExternalLink} passHref>
                      <a>
                          {getDomain(get(shop,"web"))}
                      </a>
                      </Link>
                  ) : null}
                  </div>
              </li>
            );
        })}
    </ul>
  );
};

export default Shops;
