import Modal from "antd/lib/modal/Modal";
import Steps from "components/memberSelection/Steps";

import styles from "components/memberSelection/MemberSelection.module.scss";

const MemberSelection = ({ modalVisible, setModalVisible }) => {
  return (
    <Modal
      centered
      visible={modalVisible}
      closable={false}
      maskClosable={false}
      className={styles.modal}
      width="95%"
      footer={null}
    >
      <Steps setModalVisible={setModalVisible} />
    </Modal>
  );
};

export default MemberSelection;
