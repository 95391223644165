
const CustomGaEvents = {

	pageType: (page) => {
		const data = {
			'page.category.pageType': page,
		};
		return prepareData(data, "page_category_identification");
	},

	contact_click: (type,valueType,value) => {
		const data = {};
		data.type = type;
		data[valueType] = value;
		return prepareData(data, "contact_click");
	},

	social_media: (type) => {
		const data = {
			type: type,
		};

		return prepareData(data, "social_media");
	},

	form_submit: (type) => {
		const data = {
			type: type,
		};

		return prepareData(data, "form_submit");
	},

};


const prepareData = (data = {}, event = null) => {
	const ecommerceJson = {};
	if (event) {
		ecommerceJson.event = event;
	}

	if (data) {
		ecommerceJson.ecommerce = data;
	}

	return ecommerceJson;
};

export default CustomGaEvents;
