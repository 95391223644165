import styles from "./MenuOpener.module.scss"

const MenuOpener = (props) => {
    const {setSideMenuOpen} = props;

    return (
        <a className={styles.menuOpener} onClick={() => {
            setSideMenuOpen(true)
        }}>
            <i className="fas fa-bars" aria-hidden="true"></i>
        </a>
    )
}


export default MenuOpener;