import { get } from "lodash";
import useCompare from "grandus-lib/hooks/useCompare";

import Link from "next/link";

import styles from "components/header/CompareButton.module.scss";

const CompareButton = () => {
  const { compare } = useCompare();
  const itemsCount = get(compare, 'productIds', []).length;
  return (
    <Link href="/porovnanie" as={`/porovnanie`}>
      <a className={styles?.compareButton}>
        <div className={styles?.icon}>
          {itemsCount > 0 ? <span className={styles?.badge}>{itemsCount}</span> : null}
          <img src="/img/compare.svg" alt="compare" className={"svg " + styles.compareButtonImg} />
        </div>
      </a>
    </Link>
  );
};

export default CompareButton;
