import { isEmpty, map, get } from "lodash";
import useMember from "utils/useMember";

import Shops from "components/memberSelection/Shops";

import styles from "components/memberSelection/MemberSelection.module.scss";

const Disctricts = ({ setModalVisible, ...rest }) => {
  const { 
    operationUnits, 
    member, 
    setDistrict,
    setOperationUnit,
    unsetOperationUnit
  } = useMember();
  const handleClick =
    (district = null) =>
    (e) => {
      if (!isEmpty(district)) {
        setDistrict(district);
      }
    };

  // sort groupped operation units by key ASC
  const orderedOperationUnits = Object.keys(operationUnits)
    .sort((a, b) => {
      return a.localeCompare(b, 'sk', { sensitivity: 'accent' });
    })
    .reduce((obj, key) => {
      obj[key] = operationUnits[key];
      return obj;
    }, {});

  return (
    <ul>
      {map(orderedOperationUnits, (operationUnit, district) => (
        <li
          key={`district-${district}`}
          onClick={handleClick(district)}
          className={`${member?.district === district ? styles.active : ""}`}
        >
          {district === "undefined" ? "Ostatné" : district} ({operationUnit.length})
          {member?.district == district ? (
            <div className={styles.shops}>
              <Shops 
                setModalVisible={setModalVisible}  
                member={member}
                shops={get(operationUnits, district, [])}
                setOperationUnit={setOperationUnit}
                unsetOperationUnit={unsetOperationUnit}
              />
            </div>
          ) : null }
        </li>
      ))}
    </ul>
  );
};

export default Disctricts;
