import styles from "./MegaMenu.module.scss"
import map from "lodash/map";
import get from "lodash/get";
import Link from "next/link";
import isEmpty from "lodash/isEmpty";
import {Fragment} from "react";

const SubCategories = ({items,categoryLink,externalUrl}) => {
	if(isEmpty(items)){
		return ""
	}

	const itemsLengs = items.length >= 5 ? 5 : items.length;

	return(
		<div className={styles.subCategories}>
			<div className={styles.content}>
				{map(items,(item,index) => {
					if(index >= 5){
						return ""
					}
					return(
						<Fragment key={`subCategories-item-${index}-${item?.id}`}>
							{item?.externalUrl ? (
								<a href={get(item, "externalUrl")}>
									{get(item, "name")}
									{(index + 1 ) != itemsLengs ? "," : ""}
									&nbsp;
								</a>
							) : (
								<Link
									href="/kategoria/[category]"
									as={`/kategoria/${get(item, "urlName")}`}
									scroll={true}
								>
									<a>
										{get(item, "name")}
										{(index + 1 ) != itemsLengs ? "," : ""}
										&nbsp;
									</a>
								</Link>
							)}
						</Fragment>
					)
				})}

			</div>
			{items?.length >= 5 ? (
				<>
					<>
						{externalUrl ? (
							<Link
								href={externalUrl}
								scroll={true}
							>
								<a className={styles.more}>...viac</a>
							</Link>
						) : ""}
					</>
					<>
						{categoryLink ? (
							<Link
								href="/kategoria/[category]"
								as={`/kategoria/${categoryLink}`}
								scroll={true}
							>
								<a className={styles.more}>...viac</a>
							</Link>
						) : ""}
					</>
				</>
			) : ""}
		</div>
	)
}

const MegaMenu = ({items}) => {
	return(
		<div className={styles.megaMenu}>
			<div className={"container row"}>
				{map(items,(item,index) => {
					return(
						<div className={"col-3"} key={`megaMenu-item-${index}-${item?.id}`}>
							{item?.externalUrl ? (
								<>
									<Link href={get(item, "externalUrl")}>
										<a>
											{get(item, "name")}
										</a>
									</Link>
									{!isEmpty(item?.children) ? (
										<SubCategories items={item?.children} externalUrl={get(item, "externalUrl")} />
									) : ""}
								</>
							) : (
								<>
									<Link
										href="/kategoria/[category]"
										as={`/kategoria/${get(item, "urlName")}`}
										scroll={true}
									>
										<a>{get(item, "name")}</a>
									</Link>
									{!isEmpty(item?.children) ? (
										<SubCategories items={item?.children} categoryLink={get(item, "urlName")} />
									) : ""}
								</>
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}
export default MegaMenu;